import React, { useEffect, useState } from 'react'
import { Divider, Layout, Button, Menu, Dropdown } from 'antd'
import { Link } from 'gatsby'
import './style.less'
import { httpGet } from '../../services/http'
import { isMobile } from 'react-device-detect'
import CookieModal, { withdrawConsent } from '../../components/CookieModal'
import { MenuOutlined } from '@ant-design/icons'

const { Content, Footer } = Layout

export default function LandingPageContainer(props) {
    const [cookieValue, setCookieValue] = useState<number>(new Date().valueOf())

    useEffect(() => {
        if (!props?.pathName) return
        httpGet(`/landing/status?path_name=${props?.pathName}`)
    }, [props?.pathName])

    const functionItems = [
        <Menu.Item key="funktioner-0">
            <Link to="/udlandsophold">Udlandsophold</Link>
        </Menu.Item>,
        <Menu.Item key="funktioner-0">
            <Link to="/42-dages-reglen">42-dages-reglen</Link>
        </Menu.Item>,
        <Menu.Item key="funktioner-1">
            <Link to="/bilagsindsamling">Bilagsindsamling</Link>
        </Menu.Item>,
        <Menu.Item key="funktioner-2">
            <Link to="/skatterapporter">Skatterapporter</Link>
        </Menu.Item>,
        <Menu.Item key="funktioner-3">
            <Link to="/team-op-med-revisor">Team op med revisor</Link>
        </Menu.Item>,
        // <Menu.Item key="funktioner-3">
        //     <Link to="/til-raadgiver">Til revisoren</Link>
        // </Menu.Item>,
    ]

    const menu = (
        <Menu>
            {functionItems.map((i) => {
                return (
                    <>
                        {i}
                        <Menu.Divider />
                    </>
                )
            })}
        </Menu>
    )

    const langMenu = (
        <Menu>
            <Menu.Item key="lang-0">
                <Link to="/en">🇬🇧 English</Link>
            </Menu.Item>
        </Menu>
    )

    const pcMenu = (
        <>
            <Link to="/loven">
                <Button type="primary">Loven</Button>
            </Link>
            <Dropdown overlay={menu}>
                <Button
                    style={{
                        borderLeft: 'solid 1px',
                    }}
                    type="primary"
                >
                    Funktioner
                </Button>
            </Dropdown>
            {/* <Link to="/blog">
                <Button
                    type="primary"
                    style={{
                        borderLeft: 'solid 1px',
                    }}
                >Blog</Button>
            </Link> */}
            {/* <Dropdown overlay={langMenu}>
                <Button
                    style={{
                        borderLeft: 'solid 1px',
                    }}
                    type="primary"
                >
                    🇩🇰 Dansk
                </Button>
            </Dropdown> */}
        </>
    )

    const mobileDropDown = (
        <Menu>
            <Menu.Item key="0">
                <Link to="/loven">Loven</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.ItemGroup title="Funktioner">{functionItems}</Menu.ItemGroup>
            <Menu.Divider />
            <Menu.Item key="0">
                <Link to="/blog">Blog</Link>
            </Menu.Item>
            <Menu.Divider />
            {/* <Menu.Item key="lang-0">
                <Link to="/en">🇬🇧 English</Link>
            </Menu.Item> */}
        </Menu>
    )

    const mobileMenu = (
        <Dropdown overlay={mobileDropDown} trigger={['click']}>
            <MenuOutlined style={{ fontSize: 20 }} />
        </Dropdown>
    )

    return (
        <>
            <Layout>
                <div style={{ position: 'absolute', top: 0, left: 0, height: '56px' }}>
                    <div className="nav-header-reporting" style={{ height: '56px' }}>
                        <span>
                            <div className="header-content-reporting">
                                <Link to="/">
                                    <div style={{ marginRight: 20 }} className="logoImg" />
                                </Link>
                                {(isMobile && mobileMenu) || pcMenu}
                            </div>
                        </span>
                        <span
                            style={{
                                justifySelf: 'flex-end',
                                alignSelf: 'center',
                                marginRight: 30,
                                display: 'flex',
                                flexWrap: 'nowrap',
                            }}
                        >
                            <a href={'/app'}>
                                <Button shape="round" type="primary" style={{ marginLeft: 20 }}>
                                    Log ind
                                </Button>
                            </a>
                            <a href={'/signup'}>
                                <Button
                                    shape="round"
                                    type="default"
                                    style={{
                                        borderColor: '#7EAC55',
                                        backgroundColor: '#7EAC55',
                                        marginLeft: 20,
                                    }}
                                >
                                    Prøv gratis
                                </Button>
                            </a>
                        </span>
                    </div>
                </div>
                <div style={{ width: '100%', marginTop: '56px' }} />
                <Content>{props.children}</Content>
                <Footer>
                    <Divider />
                    <p style={{ color: 'rgba(0,0,0,0.5)' }}>© Ikenson ApS 2020-2021</p>
                    <span
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            maxWidth: 600,
                        }}
                    >
                        <Link to="/privacy/">
                            <Button style={{ marginBottom: 10 }}>Privacy Policy</Button>
                        </Link>
                        <a href="mailto:info@ll33a.dk">
                            <Button style={{ marginBottom: 10 }}>
                                Contact us on info@ll33a.dk
                            </Button>
                        </a>
                        <Button
                            style={{ marginBottom: 10 }}
                            onClick={() => {
                                withdrawConsent()
                                setCookieValue(new Date().valueOf())
                            }}
                        >
                            Change cookie consent
                        </Button>
                    </span>
                </Footer>
                <CookieModal checkCookies={cookieValue} />
            </Layout>
        </>
    )
}
